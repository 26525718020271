<template>
    <g-layout>
        <generator />
    </g-layout>
</template>

<script>
import Generator from "@/components/Generator.vue";

export default {
    name: "GeneratorPage",
    components: {Generator},
    data: () => ({
    }),
    computed: {
    }
}
</script>

<style scoped>

</style>
