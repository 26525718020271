<template>
    <div>
        <v-text-field v-if="$page.props.isAdmin" v-model="params.title"/>
        <h2 v-else v-html="params.title" />
        <v-row align-content="stretch">
            <v-col v-if="$page.props.isAdmin">
                <v-textarea v-model="params.text"></v-textarea>

            </v-col>
            <v-col class="js-runner__text" v-if="params.text" >
                <VueShowdown :markdown="params.text"/>
            </v-col>
        </v-row>
        <v-row style="min-height: 50vh;">
            <v-col class="">
                <v-chip label x-small pill class="mb-1">Код</v-chip>
                <div class="d-flex flex-column elevation-1">
                    <code-editor
                        v-if="$page.props.isAdmin"
                        lang="js"
                        v-model="params.hidden"
                        style="border-bottom: 1px solid #eeeeee;"
                    />
                    <code-editor
                        :readonly="!$page.props.isAdmin"
                        lang="js" v-model="params.prepend" class="pb-0"
                        :style="$page.props.isAdmin?{
                            borderBottom: '1px solid #eeeeee'
                        }:{}"
                    />
                    <code-editor lang="js" v-model="code" class="flex-grow-1 pt-0" />
                    <code-editor
                        v-if="isAdmin"
                        lang="js"
                        v-model="params.validate"
                        style="border-top: 1px solid #eeeeee;"
                    />
                </div>
            </v-col>
            <v-col class="d-flex flex-column align-start">
                <v-btn
                    @click="run"
                    class="mb-2"
                    tile color="primary"
                >Запустить</v-btn>
                <v-chip label x-small pill class="mb-1">Консоль</v-chip>
                <div
                    class="flex-grow-1 elevation-1 pa-2 align-self-stretch"
                    style="white-space: pre-wrap;"
                    v-html="output"
                />
            </v-col>
        </v-row>
        <v-btn v-if="isAdmin" @click="save">Сохранить</v-btn>
        <v-btn v-if="params.prev && isAdmin" @click="prev">Назад</v-btn>
        <v-btn v-if="params.next" @click="next">Далее</v-btn>
        <v-btn v-else-if="isAdmin" @click="addNext">Добавить следующий шаг</v-btn>
<!--        {{params1}}-->
    </div>
</template>

<script>
import CodeEditor from "@/components/CodeEditor.vue";
import axios from "axios";
import {Inertia} from "@inertiajs/inertia";

export default {
    name: "JSRunner",
    components: {CodeEditor},
    comments: {CodeEditor},
    props: {
        file: {},
        params: {},
    },
    data: () => ({
        output: '',
        code: '',

    }),
    computed: {
        isAdmin(){
            return this.$page?.props?.isAdmin
        }
    },
    mounted() {
        this.code = this.params.code
    },
    watch: {
        params: {
            deep: true,
            handler(){
                this.code = this.params.code
            }
        }
    },
    methods: {
        fakeLog(...args){
            console.log(...args)
            if(this.output.length > 1) this.output += '\n'
            this.output += args.map(item => {
                if(typeof item === "object"){
                    return '// '+item+'\n'+JSON.stringify(item, null, 2)
                } else {
                    return item;
                }
            }).join(' ')
        },
        fakeError(error){
            console.error(error)
            this.fakeLog(`<div class="red lighten-3 error--text pa-2 rounded">${error}</div>`)
        },
        eval(code){
            try {
                return eval(code.replaceAll('console.log', 'this.fakeLog'))
            } catch (err) {
                this.fakeError('<strong>Ошибка:</strong> ' + err.message)
                return false
            }
        },
        evalCode(){
            this.output = ''
            let code = [
                this.params.hidden ?? '',
                this.params.prepend ?? '',
                this.code,
                this.params.validate ?? ''
            ].join('\n')
            // console.log(code)
            return this.eval(code)

        },
        async run(){
            let success = this.evalCode()
            if (typeof  success === 'string' || !success) {
                this.fakeError('<strong>Задание не выполнено</strong>' + (success ? `: ${success}`:''))
                return false
            }
            else {
                // console.log('Задание выполнено')
                return true
            }
        },
        async save(){
           await axios.post(`/js/quest/${this.params.id}/save`, {...this.params, code: this.code})
        },
        async next(){
            if(await this.run() || this.isAdmin) {
                Inertia.reload({method: 'post', data: {next: this.params.id}})
                this.output = ''
                // Inertia.visit(`/js/quest/${this.params.next}`)
            } else {
                this.fakeError("Задание не выполнено")
            }
        },
        async addNext(){
            let res = await axios.post(`/js/quest/${this.params.id}/new`);
            this.params.next = res
            Inertia.reload({data: {id: res}})
        }
    }
}
</script>

<style>
.js-runner__output_error{
    color:red;
}
.js-runner__text table{
    border-collapse: collapse;
}
.js-runner__text table td,
.js-runner__text table th
{
    border: 1px solid black;
    padding: 3px;
}
</style>
