<template>
    <v-row style="position: relative;">
        <v-col style="max-width: 200px;overflow-y: auto;max-height: calc(100vh - 100px);position: sticky;top:100px;">
            <v-list class="css-properties-list">
                <v-list-item link v-for="item in items" :key="item.id"
                             @click="selected = item.id"  class="css-properties-list__item">
                    <v-list-item-content>
                        <v-list-item-title>{{item[title]}}</v-list-item-title>
                        <v-list-item-subtitle v-if="subTitle" style="white-space: break-spaces;">{{item[subTitle]}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
        <v-col style="overflow-y: auto;max-height: calc(100vh - 65px);">
            <v-autocomplete
                :items="items"
                :item-text="title"
                item-value="id"
                v-model="selected"
                :label="label"
            >
                <template #item="{item}">
                    {{item[title]}} ({{item[subTitle]}})
                </template>
                <template #selection="{item}">
                    {{item[title]}} ({{item[subTitle]}})
                </template>
            </v-autocomplete>
            <template v-if="selectedItem">
                <p v-if="selectedItem.desc" v-html="selectedItem.desc" style="font-size: 90%;white-space: pre-wrap; word-break: keep-all;"/>
                <slot name="item" :item="selectedItem" />
                <web-code :code="selectedItem.code" :key="JSON.stringify(selectedItem.code)"/>
            </template>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ListItems",
    props: {
        items: {},
        title: {default: 'title'},
        subTitle: {default: null},
        label: {default: 'Название'}
    },
    data: () => ({
        selected: null,
    }),
    computed: {
        selectedItem(){
            return this.items.find(i => i.id === this.selected)
        }
    },
    mounted() {
        this.selected = this.items[0]?.id
    }
}
</script>

<style>
.css-properties-list .v-list-item__title{
    font-size: 12px;
    white-space: break-spaces;
}
.css-properties-list__item{
    /*padding-left: 30px;*/
    border: 1px solid #1976d2;
    /*margin-left: 20px;*/
    margin-top: 10px;
    border-radius: 10px;
}
</style>
