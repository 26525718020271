<template>
    <g-layout>
        <div>

            <div class="d-flex flex-wrap align-center justify-between pb-2" style="gap: 10px;">
                <v-tooltip
                    v-for="(question, index) in questions"
                    :key="index"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :color="active === index? 'primary':(answers[question.id]?'secondary':'')"

                            v-bind="attrs"
                            v-on="on"
                            style="min-width: 0;"
                            @click="active = index"
                        >
                            {{ index + 1}}
                        </v-btn>
                    </template>
                    <span>{{question.text}}</span>
                </v-tooltip>
                <v-spacer />
                <v-dialog v-model="confirmEnd" width="300">
                    <template v-slot:activator="{ props, on}">
                        <v-btn
                            color="primary"
                            v-bind="props"
                            v-on="on"
                        >
                            Завершить тест
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            Завершить тест?
                        </v-card-title>
                        <v-card-text>
                            Вы уверены, что хотите завершить тест? Действие необратимо!
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="red" dark outlined @click="confirmEnd = false">Нет</v-btn>
                            <v-spacer />
                            <v-btn color="green" dark outlined @click="finish">Да</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
<!--            <div class="d-flex align-center justify-between" style="gap: 10px;">-->
<!--                <v-btn :disabled="active < 1" @click="active&#45;&#45;">Предыдущий</v-btn>-->
<!--                <v-autocomplete :items="questionsSelect" v-model="active"/>-->
<!--                <v-btn :disabled="active >= (parseInt(questions.length) - 1)" @click="active++">Следующий</v-btn>-->
<!--            </div>-->
            <v-card>
                <v-card-actions>
                    <v-btn  :disabled="active < 1" @click="active--" color="red" outlined>Назад</v-btn>
<!--                    <v-spacer />-->
                    <v-btn :disabled="active >= (parseInt(questions.length) - 1)" @click="active++" color="green" outlined>Далее</v-btn>
                </v-card-actions>
                <v-card-title>
                    {{activeQuestion.text}}
                </v-card-title>
                <v-card-text>
                    <template v-if="activeQuestion.type === 1">
                        <v-radio-group v-model="activeQuestionAnswer" style="display: grid;grid-template-columns: 1fr 1fr;">
                            <v-radio
                                v-for="(item, index) in activeQuestion.items"
                                :key="index"
                                :label="item"
                                :value="index"
                            />
                        </v-radio-group>
                    </template>
                    <template v-if="activeQuestion.type === 2">
                        <v-checkbox
                            v-for="(item, index) in activeQuestion.items"
                            :key="index"
                            :label="item"
                            :value="index"
                            v-model="activeQuestionAnswer"
                        />
                    </template>
                    <template v-if="activeQuestion.type === 3">
                        <v-text-field v-model="activeQuestionAnswer" label="Ответ" />
                    </template>
                </v-card-text>
            </v-card>
        </div>
    </g-layout>
</template>

<script>
import {Inertia} from "@inertiajs/inertia";
// import { router } from '@inertiajs/vue2'

export default {
    name: "Test",
    props: ['questions'],
    data: () => ({
        confirmEnd: false,
        active: 0,
        answers: {},
        questions1: [
            {
                text: 'Вопрос?',
                type: 2,
                items: [
                    'Ответ 1',
                    'Ответ 2',
                    'Ответ 3',
                ]
            },
            {
                text: 'Вопрос?',
                type: 1,
                items: [
                    'Ответ 1',
                    'Ответ 2',
                    'Ответ 3',
                ]
            },
            {
                text: 'Вопрос?',
                type: 3
            },
        ]
    }),
    computed: {
        questionsSelect(){
            return this.questions.map((v, i) => {
                return {
                    value: i,
                    text: i+1 + '. ' + v.text,
                    label: i+1 + '. ' + v.text,
                }
            })
        },
        activeQuestion(){
            return this.questions[this.active]
        },
        activeQuestionAnswer: {
            set(val){
                this.$set(this.answers, this.activeQuestion.id, val)
            },
            get(){
                let defaultValue = this.activeQuestion.type === 2 ? []:null
                return this.answers[this.activeQuestion.id] ?? defaultValue
            }
        }
    },
    methods: {
        finish(){
            Inertia.post('/test', {
                answers: this.answers,
                count: this.questions.length,
                userId: this.$page.props.user.id
            })
        }
    }
}
</script>

<style scoped>

</style>
