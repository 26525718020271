<template>
    <div
         :class="{
            'code-editor': true,
            'code-editor_readonly': readonly,
        }"
    >
        <prism-editor
            :readonly="readonly"
            v-model="code"
            :highlight="$highlighter(lang)"
        />
    </div>
</template>

<script>
export default {
    name: "CodeEditor",
    props: {
        lang: {},
        value: {},
        readonly: {type: Boolean, default: false}
    },
    computed: {
        code: {
            set(value){
                this.$emit('input', value)
            },
            get(){
                return this.value
            }
        }
    }
}
</script>

<style>
.code-editor{
    /*border: 1px solid #cccccc;*/
    padding: 10px;
    position: relative;
}
.code-editor_readonly{
    background: #f9f9f9;
}
.code-editor textarea{
    outline: none;
}
</style>
