<template>
  <g-layout title="CSS. Цвета и единицы измерения">
    <h3>Цвета</h3>
    <div class="d-flex" style="gap: 10px;">
      <v-alert v-for="color in colors" :key="color" :color="color" >{{color}}</v-alert>
    </div>
      <p>
          hsl = hue, saturation, lightness (оттенок, насыщенность, яркость)
      </p>
    <h3>Единицы измерения</h3>
    <v-data-table :headers="unitHeaders" :items="units" hide-default-footer :items-per-page="-1"/>
  </g-layout>
</template>

<script>
export default {
  name: "CSSUnits",
    props: ['units'],
  data: () => ({
    colors: ['green', '#00ff00', 'rgb(0,255,0)', 'hsl(120 100% 50%)', '#00ff0088', 'rgba(0,255,0, 0.5)', 'hsl(120 100% 50% / 0.5)'],
    unitHeaders: [
      {text: 'Название', value: 'title'},
      {text: 'Единица измерения', value: 'unit'},
    ],
    // units: [], //require('@/csv/units.csv')
  })
}
</script>

<style scoped>

</style>
