<template xmlns="http://www.w3.org/1999/html">
    <g-layout>
        <h1>Web-программирование</h1>
        <p><strong>Веб-программирование</strong> — раздел программирования, ориентированный на разработку веб-приложений.</p>
        <p><strong>Веб-приложение</strong> — клиент-серверное приложение, в котором клиент взаимодействует с веб-сервером при помощи браузера.</p>
        <p><strong>Веб-сайт</strong> — одна или несколько логически связанных между собой веб-страниц.</p>
        <p><strong>Важное примечание: </strong>Веб-сайт является спорным термином. В рамках дисциплины будем считать его подмножеством веб-приложений, но многие считают его в корне другим понятием не пересекающимся в с веб приложением.</p>
        <p>Отличия веб-сайта от веб приложения:</p>
        <ul class="mb-4">
            <li>веб-сайт не хранит данные пользователя на сервере, только локально (отсутствует авторизация, формы ввода данных не отправляют информацию);</li>
            <li>веб-сайт написан с использованием фронтенд-технологий (HTML, CSS, JavaScript), для разработки достаточно веб-браузера и интегрированной среды разработки (редактора);</li>
            <li>веб-приложение написано с использованием И фронтенд-технологий, И бекенд-технологий (PHP, C#, Java, JavaScript/NodeJs), для разработки требуется разворачивание локального веб-сервера (Apache, Nginx), а зачастую сервера БД и пр.</li>
        </ul>
        <p><b>Веб-страница</b> — документ или информационный ресурс, доступ к которому осуществляется с помощью веб-браузера.</p>
        <p><b>Веб-страница (с технической точки зрения)</b> — текстовый файл на языке HTML.</p>
        <div class="d-flex align-center" style="gap: 20px;max-width: 100%">
            <img src="/img/file.jpg" class="flex-shrink-1" style="width: 40%"/>
            <v-icon x-large>mdi-arrow-right-circle</v-icon>
            <img src="/img/browser.jpg" style="box-shadow: black 0 0 3px; width: 40%;"/>
        </div>
        <v-list-group v-for="block in blocks" :key="block.id">
            <template v-slot:activator>
                <v-list-item-title>{{block.title}}</v-list-item-title>
            </template>
            <inertia-link
                as="v-list-item"
                v-for="lecture in block.lectures"
                :key="lecture.id"
                :href="`/lecture/${lecture.id}`"
            >
                <v-list-item-title>{{lecture.title}}</v-list-item-title>
            </inertia-link>
        </v-list-group>
        <v-treeview
            :items="blocks"
            item-key="id"
            item-text="title"
            item-children="lectures"
            activatable
            @update:active="onSelect"
        />

    </g-layout>
</template>
<script>

import {mapState} from "vuex";
import {Inertia} from "@inertiajs/inertia";
import axios from "axios";

export default {
    name: "Home",
    props: [
        'blocks',
    ],
    // remember: ['expanded'],
    data: () => ({
    }),
    computed: {
        ...mapState('dictionary', ['dictionary', 'dictionaryLoading']),
    },
    mounted() {
    },
    watch: {
    },
    methods: {
        onSelect(active){
            console.log(active)
        }
    }
}
</script>

<style>
</style>
