var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g-layout',[_c('h1',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('list-items',{attrs:{"items":_vm.items,"title":"tag","sub-title":"title","label":"Тег"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.attributes && item.attributes.length > 0)?_c('v-data-table',{staticClass:"my-3",attrs:{"headers":[
                        {value: 'attribute', text: 'Аттрибут'},
                        {value: 'title', text: 'Назначение'},
                        {value: 'desc', text: 'Описание'} ],"items":item.attributes,"hide-default-footer":"","title":"Специальные атрибуты тега"},scopedSlots:_vm._u([{key:"item.desc",fn:function(ref){
                    var value = ref.value;
return [_c('div',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(value)}})]}}],null,true)}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }