<template>
    <g-layout>
        <h1>Correct Answer Admin</h1>
        <AdminCorrectAnswerItem
            v-for="item in items" :key="item.id"
            :item="item"
            class="mb-5"
        />
    </g-layout>
</template>

<script>
import AdminCorrectAnswerItem from "@/components/AdminCorrectAnswerItem.vue";

export default {
    name: "CorrectAnswer",
    components: {AdminCorrectAnswerItem},
    props: {
        items: {type: Array},
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
