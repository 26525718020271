<template>
    <g-layout>
<!--        <v-breadcrumbs :items="breadcrumbs"/>-->
        <h1>{{title}}</h1>
        <list-items :items="items" title="tag" sub-title="title" label="Тег">
            <template #item="{item}">
                <v-data-table
                    v-if="item.attributes && item.attributes.length > 0"
                    :headers="[
                        {value: 'attribute', text: 'Аттрибут'},
                        {value: 'title', text: 'Назначение'},
                        {value: 'desc', text: 'Описание'},
                    ]"
                    :items="item.attributes"
                    hide-default-footer
                    title="Специальные атрибуты тега"
                    class="my-3"
                >
                    <template #item.desc="{value}">
                        <div v-html="value" style="white-space: pre-wrap"/>
                    </template>
                </v-data-table>
            </template>
        </list-items>
    </g-layout>
</template>

<script>
import ListItems from "@/components/ListItems.vue";

export default {
    name: "HTMLTags",
    components: {ListItems},
    props: {
        title: {default: 'Теги'},
        items: {},
        breadcrumbs: {}
    }
}
</script>

<style scoped>

</style>
