import { render, staticRenderFns } from "./CSSUnits.vue?vue&type=template&id=0cfd3d84&scoped=true&"
import script from "./CSSUnits.vue?vue&type=script&lang=js&"
export * from "./CSSUnits.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cfd3d84",
  null
  
)

export default component.exports