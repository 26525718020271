<template>
    <g-layout>
        <h1>Квест</h1>
        <div>
            <v-btn v-for="step in steps" :key="step.id" @click="goto(step.id)">
                {{step.title}}
            </v-btn>
        </div>
        <JSRunner :params="params"/>
    </g-layout>
</template>

<script>
import CodeEditor from "@/components/CodeEditor.vue";
import JSRunner from "@/components/JSRunner.vue";
import {Inertia} from "@inertiajs/inertia";

export default {
    name: "JSQuest",
    components: {CodeEditor, JSRunner},
    props: {
        params: {},
        steps: {},
    },
    methods: {
        goto(id){
            Inertia.reload({data: {id}})
        }
    }
}
</script>

<style scoped>

</style>
