<template xmlns="http://www.w3.org/1999/html">
    <g-layout>
        <v-card class="card-100">
            <v-card-title>{{active.title}}</v-card-title>
            <v-card-text>
                <v-row align="stretch" style="height: 100%">
                    <v-col
                        class="overflow-y-auto d-flex flex-column text-area-code"
                    >
                        <v-textarea
                            v-model="active.code" @change="showCode"
                            :error="codeErrorsCount > 0"
                            :error-count="codeErrorsCount"
                            :error-messages="codeErrors"
                        >
                            <template #append>
                                <v-btn icon @click="showCode">
                                    <v-icon color="green">mdi-play</v-icon>
                                </v-btn>
                            </template>
                        </v-textarea>
                    </v-col>
                    <v-col>
                        {{frameLink}}
                        <v-btn icon @click="getCode"><v-icon>mdi-code</v-icon></v-btn>
                        <iframe class="iframe-code" ref="iframe" name="code" :key="codeKey" :src.sync="codeLink"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </g-layout>
</template>
<script>

import {mapState} from "vuex";
import {Inertia} from "@inertiajs/inertia";
import axios from "axios";

export default {
    name: "Lecture",
    props: [
        'slides',
    ],
    // remember: ['expanded'],
    data: () => ({
        activeSlideIndex: 0,
        codeLink: null,
        codeKey: 0,
        codeErrors: null,
        frameLink: null,
    }),
    computed: {
        ...mapState('dictionary', ['dictionary', 'dictionaryLoading']),
        active(){
            return this.slides[this.activeSlideIndex]
        },
        codeErrorsCount(){
            return this.codeErrors?.length ?? 0
        },
        ttt(){
            return window.frames['code']?.location?.href
        }
    },
    mounted() {
        this.showCode()
        setInterval( () => {
            // console.log(window.frames['code'])
            this.frameLink = window.frames['code'].location.pathname + window.frames['code'].location.search
        }, 500)

    },
    watch: {
    },
    methods: {
        async showCode(){
            this.codeErrors = null
            let res = await axios.post('/lecture/1/0', {code: this.active.code})
            if(res?.data?.success) {
                this.codeLink = '/' + res?.data?.path
                this.frameLink = this.codeLink
            }
            if(res.data?.errors?.length > 0) {
                this.codeErrors = res.data.errors
            }
            this.codeKey++
        },
        async getCode(){
            let res = await axios.get(this.frameLink)
            console.log(res.data)
            // console.log(window.frames['code'])
            // console.log(window.frames['code'].document.body.innerHTML)
            // console.log(window.frames['code'].document.innerHTML)
            // console.log(window.frames['code'].innerHTML)
        }
    }
}
</script>

<style lang="scss">
.text-area-code{
    height: 100%;max-height: 100%;
    .v-input__control{
        height: 100%;
    }
    .v-input{
        margin-top: 0;
        padding-top: 0;
    }
    .v-input__slot {
        flex-grow: 1;
        align-items: start;
    }
    .v-text-field__slot{
        height: calc(100% - 20px);
        border: 1px solid black;
    }
    .v-text-field__details{
        flex-grow: 0;
    }
}
.iframe-code{
    height: 100%;
    width: 100%;
}
</style>
