<template>
    <g-layout title="CSS-свойство display">
<!--        <h1>Атрибут display</h1>-->
        <h2>Значения</h2>
        <v-select :items="displayValues" label="display" v-model="display"/>
        <web-code :code="displayExample" :key="display"/>
        <h2>Технология flexbox</h2>
        <v-select
            v-for="(list, property) in valuesList"
            :key="property"
            :items="list"
            :label="property"
            v-model="values[property]"
            clearable
        />
        <web-code :code="flexExample" :key="JSON.stringify(values)"/>
        <div
            v-for="(item, key) in codes"
            :key="key"
        >
            <h3>{{key}}</h3>
            <web-code
                :code="item"
            />
        </div>
    </g-layout>
</template>

<script>

export default {
    name: "CSSDisplay",
    props: ['codes'],
    data: () => ({
        display: 'block',
        displayValues: ['inline', 'block', 'inline-block', 'none', 'flex', 'grid'],
        valuesList: {
            'flex-wrap': ['wrap'],
            'gap': ['10px', '10px 20px'],
            'justify-content': ['flex-start', 'flex-end', 'space-between', 'space-around', 'center'],
            'align-items': ['flex-start', 'flex-end', 'center', 'stretch'],
            'flex-direction': ['row', 'row-reverse', 'column', 'column-reverse']
        },
        values: {}
    }),
    computed: {
        displayExample(){
            return {
                html: ''
                    + '<div class="item">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>\n'
                    + '<div class="item">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>\n'
                    + '<div class="item">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>\n'
                    + '<div class="item">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>\n'
                    + '<div class="item">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>\n',
                css: `.item{\n\tdisplay: ${this.display};\n\theight: 50px;\n\tbackground: #cccccc;\n}`
            }
        },
        flexExample(){
            let css = '';
            for(let [k, v] of Object.entries(this.values)){
                if(v) {
                    css += `\n    ${k}: ${v};`
                }
            }
            return {
                html:
`<div class="item">
    <div class="child">1 Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>
    <div class="child">2 Lorem ipsum dolor, sit amet consectetur adipisicing elit.Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>
    <div class="child">3 Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>
    <div class="child">4 Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>
    <div class="child">5 Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>
    <div class="child">6 Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>
</div>`,
                css:
`.item{
    display: flex;${css}
}
.child{
    border: solid;
    width: 100px;
    padding: 10px;
}`
            }
        },
    }
}
</script>

<style scoped>

</style>
