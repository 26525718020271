<template>
    <div :style="{marginBottom: this.closeTag?'5px':'0'}">
        <v-tooltip v-if="tag" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    v-bind="attrs" v-on="on"
                    @click="toggle"
                    v-text="openTag"
                    color="primary" text-color="white"
                />
            </template>
            <div v-html="tooltip" />
        </v-tooltip>
        <div v-if="opened" :style="{padding: tag?' 5px 0 5px 20px':'0'}">
            <span v-if="!tag">{{node}}</span>
            <template v-if="node.childNodes">
                <template v-for="sub in node.childNodes">
                    <HTMLTag :node="sub"/>
                </template>
            </template>
        </div>
        <span v-else> ... </span>
        <v-tooltip v-if="closeTag" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-chip
                    v-bind="attrs" v-on="on"
                    @click="toggle"
                    v-text="closeTag"
                    color="primary" text-color="white"
                />
            </template>
            <div v-html="tooltip" />
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: "HTMLTag",
    props: ['node'],
    data: () => ({
        opened: true,
    }),
    computed: {
        tag(){
            return this.node.rawTagName ?? null;
        },
        openTag(){
            let backSlash = this.single ? '/' : ''
            let attrs = this.node.rawAttrs;
            if(attrs) attrs = ' '+attrs
            return this.tag? `<${this.tag}${attrs}${backSlash}>` : null;
        },
        closeTag(){
            return (this.tag && !this.single)?`</${this.tag}>`:null;
        },
        single(){
            return Array.from(this?.node?.voidTag?.voidTags ?? new Set())?.includes?.(this.tag)
        },
        tooltip(){
            return `<div style="font-weight: bold;">${this.tag}</div>`
        }
    },
    methods: {
        toggle(){
            if(!this.single){
                this.opened = !this.opened
            }
        }
    }
}
</script>

<style scoped>

</style>
