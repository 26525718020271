<template>
    <v-app>
        <v-navigation-drawer
            v-model="drawer"
            app

        >
<!--            <v-list-item>-->
<!--                <v-list-item-content>-->
<!--                    <v-list-item-title class="text-h6">-->
<!--                        Schoolizdat-->
<!--                    </v-list-item-title>-->
<!--                    <v-list-item-subtitle>-->
<!--                        Личный кабинет-->
<!--                    </v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--            </v-list-item>-->
<!--            <v-divider></v-divider>-->
<!--            <inertia-link v-for="(link, linkIndex) in links" as="v-list-item" :href="link.to" :method="link.method?link.method:'get'" :key="linkIndex">-->
<!--                <v-list-item-content>-->
<!--                    <v-list-item-title>-->
<!--                        {{link.title}}-->
<!--                    </v-list-item-title>-->
<!--                </v-list-item-content>-->
<!--            </inertia-link>-->
            <v-treeview
                :items="menu"
                open-on-click
                open-all
            >
                <template #label="{item}">
                    <inertia-link :href="item.to" v-if="item.to">{{ item.name }}</inertia-link>
                    <span v-else>{{ item.name }}</span>
                </template>
            </v-treeview>
            <div class="pa-2 elevation-5">
                <template v-if="$page.props.isGuest" >
                    <inertia-link :href="route('login')" as="v-btn">
                        Вход
                    </inertia-link>
                    <inertia-link v-if="$page.props.isGuest" :href="route('register')" as="v-btn">
                        Регистрация
                    </inertia-link>
                </template>
                <template v-else>
                    {{$page.props.user.name}}
                    <v-btn @click="logout" class="ml-2">
                        Выход
                    </v-btn>
                </template>
            </div>
        </v-navigation-drawer>

        <v-app-bar app color="primary">
            <v-app-bar-nav-icon @click="drawer = !drawer" color="white"/>
            <div v-if="title" style="color: white;">{{title}}</div>
            <v-spacer/>

            <!--            <v-toolbar-title>Личный кабинет участника Schoolizdat</v-toolbar-title>-->
        </v-app-bar>
        <v-main>
            <div class="pa-6" style="max-height: calc(100vh - 75px);"><slot/></div>
<!--            <v-tabs  style="height: 50px">-->
<!--                <inertia-link  as="v-tab" href="/" tag="inertia-link"  :exact="true">Заявки</inertia-link>-->
<!--&lt;!&ndash;                <inertia-link  as="v-tab" href="/services" tag="inertia-link"  :exact="true">Услуги</inertia-link>&ndash;&gt;-->
<!--            </v-tabs>-->
<!--            <v-container fluid style="height: calc(100vh - 50px);">-->

<!--            </v-container>-->
        </v-main>
    </v-app>
</template>

<script>

export default {
    name: "GlobalLayout",
    props: {
        title: {}
    },
    data: () => ({
        drawer: true,
        links: [
        ],
    }),
    computed: {
        menu(){
            return  [
                { name: 'Главная', to: '/', id: 1},
                {
                    id: 2,
                    name: 'HTML',
                    children: [
                        {name: 'Введение', to: '/html/intro'},
                        {name: 'Строчные теги', to: '/html/tags/1'},
                        {id: 22, name: 'Блочные теги', children: [
                                {name: 'Обычные теги', to: '/html/tags/2'},
                                {name: 'Семантические теги', to: '/html/tags/2/1'},
                                {name: 'Теги составных конструкций', to: '/html/tags/2/2'},
                            ]
                        },
                        {name: 'Служебные теги', to: '/html/tags/3'},
                        {name: 'Теги полей ввода (строчные и блочные)', to: '/html/tags/0/3'},
                    ]
                },
                {
                    id: 3,
                    name: 'CSS',
                    children: [
                        {name: 'Введение', to: '/css/intro'},
                        {name: 'Цвета и единицы измерения', to: '/css/units'},
                        // {name: 'Селекторы', to: '/css/selectors'},
                        {name: 'Свойства', to: '/css/properties'},
                        {name: 'Свойство display', to: '/css/display'},
                        {name: 'Селекторы', to: '/css/selectors'},
                        ...Object.entries(this.$page.props.cssTypes).map( ([id, name]) => ({name, to: '/slides/css/'+id}))
                    ]
                },

                {
                    id: 4,
                    name: 'JS',
                    children: [
                        ...Object.entries(this.$page.props.jsTypes).map( ([id, name]) => ({name, to: '/slides/js/'+id}))
                    ]
                }
            ]
        }
    },
    methods: {
        logout() {
            this.$inertia.post(route('logout'));
            // this.$inertia.reload()
        },
    }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.v-alert__border{
    border-width: 1px !important;
}
.v-main__wrap{
    display: flex;
    flex-direction: column;
    height: 100vh;
}
::-webkit-scrollbar {
    width: 7px;               /* ширина scrollbar */
}
::-webkit-scrollbar-track {
    background: #eeeeee;        /* цвет дорожки */
}
::-webkit-scrollbar-thumb {
    background-color: #3f7abe;    /* цвет плашки */
    border-radius: 10px;       /* закругления плашки */
    border: 2px solid #eeeeee;  /* padding вокруг плашки */
    /*box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);*/
}

</style>
