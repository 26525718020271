// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);
import RU from 'vuetify/lib/locale/ru';
const opts = {
    lang: {
        locales: { ru: RU },
        current: 'ru',
    },
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
		themes: {
			light: {
				primary: '#3f7abe',
				secondary: '#a9d1ff',
				accent: '#FFD54F',
				error: '#b71c1c',
			},
		},
	},
}
let vuetify = new Vuetify(opts)
Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdiSvg'
})

export default vuetify
