<template>
    <v-app>
        <v-app-bar color="primary" dark>
            <v-app-bar-title color="white">Сводка</v-app-bar-title>
        </v-app-bar>
        <v-main class="pa-5">
            <v-row>
                <v-col>
                    <v-card class="q-mb-sm">
                        <v-card-title>Основная информация</v-card-title>
                        <v-card-text>
                            <v-text-field label="ФИО" type="text" placeholder="Иванов Иван Иванович" />
                            <v-text-field label="Адрес" type="text" placeholder="" />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="q-mb-sm">
                        <v-card-title>
                            Паспорт
                        </v-card-title>
                        <v-card-text>
                            <v-text-field label="Серия паспорта" type="text" mask="####" />
                            <v-text-field label="Номер паспорта" type="text" mask="#######" />
                            <v-text-field label="Кем выдан" type="text" placeholder="" />
                            <v-text-field label="Дата выдачи" type="date"  />
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card>
                        <v-card-title>Дополнительно</v-card-title>
                        <v-card-text>
                            <v-switch label="Выезд разрешен" />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "Sweety",
    data: () => ({

    })
}
</script>

<style scoped>

</style>
