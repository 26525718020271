<template>
    <v-row>
        <v-col>
            <v-combobox :items="tags" v-model="params.tag" label="тег"/>
            <v-combobox :items="classes" v-model="params.class" label="класс" />
            <v-text-field v-model="params.append" label="Атрибуты" clearable/>
            <v-combobox :items="tags" v-model="params.wrapper" label="обернуть" clearable/>
            <v-combobox v-if="params.wrapper" :items="classes" v-model="params.wrapperClass" label="класс" />
            <v-checkbox v-model="params.numerate" label="Нумеровать"/>
            <v-text-field type="number" v-model.number="params.count" label="Количество"/>
            <v-combobox v-model="params.background" label="Фон" :items="['#cccccc']"/>
            <v-combobox v-model="params.color" label="Цвет текста" :items="['#cccccc']"/>
        </v-col>
        <v-col>
            <v-btn @click="$emit('copy', {html, css})">Копировать</v-btn>
            <code-editor lang="html" v-model="html" />
        </v-col>
        <v-col>
            <code-editor lang="css" v-model="css" />
        </v-col>
    </v-row>
</template>

<script>
import CodeEditor from "@/components/CodeEditor.vue";
const INTENT = '    '
export default {
    name: "Generator",
    components: {CodeEditor},
    data: () => ({
        tags: ['div', 'span'],
        classes: ['item', 'child', 'parent', 'element'],
        texts: [
            'Южно-эфиопский грач',
            'увёл мышь',
            'за хобот',
            'на съезд ящериц.',
            'В чащах', 'юга жил-был', 'цитрус...', '— да, но', 'фальшивый экземпляръ!'
        ],
        params: {
            tag: 'div',
            class: 'item',
            wrapper: 'div',
            wrapperClass: 'parent',
            numerate: false,
            background: null,
            color: null,
            count: 4,
        }
    }),
    computed: {
        html(){
            let res = ''
            let intent = ''
            if(this.params.wrapper){
                res += `<${this.params.wrapper} class="${this.params.wrapperClass}">\n`
                intent = INTENT
            }
            let items = []
            for(let i = 0; i < this.params.count; i++){
                let cls = this.params.class
                let v = this.texts[ i % this.texts.length]
                if(this.params.numerate){
                    cls += ' '+this.params.class + i
                    v = i + '. '+ v
                }
                let append = this.params.append?(' '+this.params.append):''
                res += `${intent}<${this.params.tag} class="${cls}"${append}>${v}</${this.params.tag}>\n`
            }
            // res += this.texts.map((v, i) => {
            //     let cls = this.params.class
            //     if(this.params.numerate){
            //         cls += ' '+this.params.class + i
            //     }
            //     let append = this.params.append?(' '+this.params.append):''
            //     return `${intent}<${this.params.tag} class="${cls}"${append}>${v}</${this.params.tag}>\n`
            // }).join('')
            if(this.params.wrapper){
                res += `</${this.params.wrapper}>`
            }
            return res
        },
        css(){
            let res = ''
            if(this.params.wrapper && this.params.wrapperClass){
                res += '.'+this.params.wrapperClass+'{\n'+INTENT+'\n}\n'
            }
            res += '.'+this.params.class+'{'
            if(this.params.background){
                res += `\n${INTENT}background-color: ${this.params.background};`
            }
            if(this.params.color){
                res += `\n${INTENT}color: ${this.params.color};`
            }
            res += `\n${INTENT}\n}\n`
            return res
        }
    }
}
</script>

<style scoped>

</style>
