<template>
  <g-layout title="CSS. Введение">
    <h3>Определение</h3>
    <p>CSS (каскадные таблицы стилей)
      — формальный язык описания внешнего вида документа, написанного с использованием языка разметки</p>
    <h3>Преимущества</h3>
    <ul>
      <li>централизованное управление отображением множества документов;</li>
      <li>упрощенный контроль внешнего вида веб-страниц;</li>
      <li>наличие разработанных дизайнерских техник;</li>
      <li>возможность использования различных стилей для одного документа, в зависимости от устройства.</li>
    </ul>
    <h3>Селектор</h3>
    <p>- это часть CSS-правила, которая сообщает браузеру, к какому элементу (или элементам) веб-страницы будет применён стиль</p>
    <h3>Свойство</h3>
    <p>- это часть CSS-правила, которая указывает, какую характеристику необходимо применить элементам, которые соответствуют селектору.
      Через двоеточие указывается параметр (параметры) этой характеристики</p>
    <h3>Общий вид CSS-правила</h3>
    <web-code :code="{css: 'селектор {\n'+
'  свойство: значение;\n'+
'  свойство: значение;\n'+
'  свойство: значение;\n'+
'}\n'+
'селектор, селектор, селектор {\n'+
'  свойство: значение;\n'+
'}'}" />
    <h3>Пример</h3>
    <web-code :code="codes.example"/>
    <h3>Тот же пример в сокращенном виде</h3>
    <web-code :code="codes.example2"/>
<!--    <CSSUnits :units="units"/>-->
  </g-layout>
</template>

<script>
import WebCode from "@/components/WebCode";
import CSSUnits from "@/pages/CSS/CSSUnits";
export default {
  name: "CSSIntro",
  components: {WebCode, CSSUnits},
    props: ['codes'],
}
</script>

<style scoped>

</style>
