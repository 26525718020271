<template>
    <g-layout title="CSS-свойства">
      <v-row style="position: relative;">
        <v-col style="max-width: 200px;overflow-y: auto;max-height: calc(100vh - 65px);position: sticky;top:100px;">
          <v-list class="css-properties-list">
            <v-list-group v-for="(items, key) in tree" :key="key" class="css-properties-list__group">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{key}}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item link v-for="item in items" :key="item.id"
                           @click="prop = item.property"  class="css-properties-list__item">
                <v-list-item-content>
                  <v-list-item-title>{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.property}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
    <!--      <v-alert-->
    <!--          color="primary" dark-->
    <!--          v-for="item in csv"-->
    <!--          :outlined="item.prop !== prop"-->
    <!--          @click="prop = item.prop"-->
    <!--          style="cursor: pointer"-->
    <!--      >-->
    <!--        <div style="font-size: 50%;">{{item.type}}</div>-->
    <!--        <div style="font-size: 75%;">{{item.title}}</div>-->
    <!--        {{item.prop}}-->
    <!--      </v-alert>-->
        </v-col>
        <v-col style="overflow-y: auto;max-height: calc(100vh - 65px);">
          <v-autocomplete :items="props" v-model="prop" label="CSS-свойство"/>
          <p v-if="propData && propData.desc" v-html="propData.desc" style="font-size: 90%;"/>
          <web-code v-if="codeSample" :code="codeSample" :key="JSON.stringify(codeSample)"/>
        </v-col>
      </v-row>
    </g-layout>
</template>

<script>
import WebCode from "@/components/WebCode";
export default {
  name: "CSSProperties",
  components: {WebCode},
    props: ['csv'],
  data: () => ({
    // csv: require('@/csv/css.csv'),
    prop: null,
  }),
  computed: {
    tree(){
      let res = {}
      for(let item of this.csv){
        res[item.type] ??= []
        res[item.type].push(item)
      }
      return res
    },
    props(){
      return this.csv.map(v => ({text: v.title ?? v.property, value: v.property}))
    },
    propData(){
      return this.csv.find(v => v.property === this.prop)
    },
    codeSample(){
      let item = this.propData
      if(!item) return null
      let values = item.value.split(';')
      let html = ''
      let css = ''
      let tag = item.tag || 'div'
      let text = 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.'
        if (item.bodyCSS){
            css += ` body{${item.bodyCSS}}\n`
        }
      if(item.itemCSS){
        css += `.item{${item.itemCSS}}\n`
      }
      if(item.bodyCss){
        css += `body{${item.bodyCSS}}\n`
      }
      for(let i in values){
        html += `<${tag} class="item item${i}">Текст ${i}. ${text}</${tag}>\n`
        css += `.item${i}{\n\t${item.property}: ${values[i]};\n}\n`
      }
      return {html, css}
    }
  },
  mounted() {
    this.prop = this.props[0].value
  }
}
</script>

<style scoped>
html, body{
  overflow: hidden;
}
.css-properties-list .v-list-item__title{
  font-size: 12px;
  white-space: break-spaces;
}
.css-properties-list__item{
  /*padding-left: 30px;*/
  border: 1px solid #1976d2;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

</style>
