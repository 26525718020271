<template>
    <g-layout>

        <form @submit.prevent="submit">
            <v-form @submit.prevent="submit">
                <v-card class="pa-6 ma-auto" max-width="600">
                    <v-card-title>Авторизация в личном кабинете</v-card-title>
                    <v-card-text>
                        <jet-validation-errors class="mb-4" />
                        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                            {{ status }}
                        </div>
                        <v-text-field label="Адрес электронной почты" type="email" v-model="form.email" required autofocus />
                        <v-text-field label="Пароль" type="password" v-model="form.password" required />
                        <v-checkbox label="Запомнить меня" name="remember" v-model="form.remember"  />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn type="submit" color="primary" :disabled="form.processing">
                            Войти
                        </v-btn>
                        <!--                    <inertia-link v-if="canResetPassword" :href="route('password.request')" class="pa-5">-->
                        <!--                        Забыли пароль?-->
                        <!--                    </inertia-link>-->
                    </v-card-actions>
                </v-card>
            </v-form>
        </form>
    </g-layout>
</template>

<script>
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo'
    import JetButton from '@/Jetstream/Button'
    import JetInput from '@/Jetstream/Input'
    import JetCheckbox from '@/Jetstream/Checkbox'
    import JetLabel from '@/Jetstream/Label'
    import JetValidationErrors from '@/Jetstream/ValidationErrors'

    export default {
        components: {
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetCheckbox,
            JetLabel,
            JetValidationErrors
        },

        props: {
            canResetPassword: Boolean,
            status: String
        },

        data() {
            return {
                form: this.$inertia.form({
                    email: '',
                    password: '',
                    remember: false
                })
            }
        },

        methods: {
            submit() {
                this.form
                    .transform(data => ({
                        ... data,
                        remember: this.form.remember ? 'on' : ''
                    }))
                    .post(this.route('login'), {
                        onFinish: () => this.form.reset('password'),
                    })
            }
        }
    }
</script>
