<template>
    <g-layout :title="type.title">
        <v-row style="position: relative;">
            <v-col v-if="menu" style="max-width: 150px;overflow-y: auto;max-height: calc(100vh - 85px);position: sticky;top:100px;">
                <v-list class="js-slides-list">
                    <v-list-item link v-for="(item, index) in items" :key="item.id"
                                 @click="active = index"  class="js-slides-list__item">
                        <v-list-item-content>
                            <v-list-item-title>{{item.title}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <template v-if="isAdmin">
                        <v-list-item
                                     @click="add"  class="js-slides-list__item">
                            <v-list-item-content>
                                <v-list-item-title>+</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            @click="copy"  class="js-slides-list__item">
                            <v-list-item-content>
                                <v-list-item-title>Добавить копию</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
                <!--      <v-alert-->
                <!--          color="primary" dark-->
                <!--          v-for="item in csv"-->
                <!--          :outlined="item.prop !== prop"-->
                <!--          @click="prop = item.prop"-->
                <!--          style="cursor: pointer"-->
                <!--      >-->
                <!--        <div style="font-size: 50%;">{{item.type}}</div>-->
                <!--        <div style="font-size: 75%;">{{item.title}}</div>-->
                <!--        {{item.prop}}-->
                <!--      </v-alert>-->
            </v-col>
            <v-col style="overflow-y: auto;max-height: calc(100vh - 85px);">
                <template v-if="item">
                    <v-text-field v-if="isAdmin" v-model="item.title" />
                    <div class="d-flex">
                        <v-btn icon v-if="menu" @click="menu = false"><v-icon>mdi-menu-open</v-icon></v-btn>
                        <v-btn icon v-else @click="menu = true"><v-icon>mdi-menu</v-icon></v-btn>
                        <v-btn icon :disabled="active < 1" @click="active--"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <h2 class="flex-grow-1" style="user-select: none" v-html="item.title" />
                        <v-btn icon :disabled="active >= lastIndex" @click="active++"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </div>
                    <v-textarea v-if="isAdmin" v-model="item.md"></v-textarea>
                    <VueShowdown v-if="item.md" :markdown="item.md"/>
                    <web-code :code="item" :key="item.id + '_'+item.copy" ref="code"/>
                    <template v-if="isAdmin">
                        <v-btn @click="save">Сохранить</v-btn>
                        <v-btn @click="add">Добавить слайд</v-btn>
                        <v-btn @click="copy">Добавить копию</v-btn>
                        <v-checkbox v-model="confirmDelete" label="Точно удалить"/>
                        <v-btn :disabled="!confirmDelete" @click="del">Удалить</v-btn>

                        <generator
                            @copy="item = $event"
                        />
                    </template>
                </template>
            </v-col>
        </v-row>
    </g-layout>
</template>

<script>
import axios from "axios";
import {Inertia} from "@inertiajs/inertia";
import Generator from "@/components/Generator.vue";
export default {
    name: "JSSlides",
    components: {Generator},
    props: {
        type: {},
        items: {}
    },
    data: () => ({
        activeLocal: null,
        confirmDelete: false,
        menu: true,
        // title: ''
    }),
    computed: {
        item: {
            get() {
                return this.items[this.active] ?? null
            },
            set(val){
                this.$set(this.items, this.active, {
                    ...this.item, ...val, copy: (this.item.copy ?? 0) + 1
                })
            }
        },
        isAdmin(){
            return this.$page?.props?.isAdmin
        },
        lastIndex(){
            return this.items?.length - 1
        },
        lastItemPos(){
            return this.items[this.lastIndex]?.pos ?? 0
        },
        nextItemPos(){
            return this.items[this.active + 1]?.pos
        },
        active: {
            get(){
                return this.activeLocal ?? localStorage.getItem('slides-'+this.type)
            },
            set(val){
                localStorage.setItem('slides-'+this.type, val)
                this.activeLocal = val
            }
        }
    },
    watch: {
        // item(){
        //     console.clear()
        //     console.log(`Слайд: "${this.item.title}"`)
        // }
    },
    methods: {
        async save(){
            let data = {
                title: this.item.title,
                md: this.item.md,
                css: this.$refs.code.css,
                js: this.$refs.code.js,
                html: this.$refs.code.html
            }
            await axios.post('/slides/'+this.item.id, data)
            Inertia.reload()
        },
        async add(){
            let data = {
                title: 'Название',
                // js: null,
                type: this.type.id,
                pos: this.lastItemPos + 100,
            }
            if(this.type.id > 1){
                data.html = '<!-- code -->'
            }
            await axios.post('/slides', data)
            Inertia.reload()
        },
        async copy(){
            let pos = this.nextItemPos?
                parseInt((this.nextItemPos + this.item.pos) * 0.5):
                this.lastItemPos + 100

            await axios.post('/slides', {
                title: this.item.title + ' Копия',
                md: this.item.md,
                css: this.$refs.code.css,
                js: this.$refs.code.js,
                html: this.$refs.code.html,
                type: this.type.id,
                pos
            })
            Inertia.reload()
        },
        async del(){
            this.confirmDelete = false
            await axios.delete('/slides/'+this.item.id)
            Inertia.reload()
            this.active--;
        }
    }
}
</script>

<style>
html, body{
    overflow: hidden;
}
.js-slides-list .v-list-item__title{
    font-size: 12px;
    white-space: break-spaces;
}
.js-slides-list__item{
    /*padding-left: 30px;*/
    border: 1px solid #1976d2;
    /*margin-left: 20px;*/
    margin-top: 10px;
    border-radius: 10px;
}
</style>
