<template>
    <g-layout>
        <h3>Правильный вопрос</h3>
        <v-data-table
            v-if="questions && questions.length > 0"
            :headers="headers"
            :items="questions"
        >
            <template #top>
                Заданные вопросы
            </template>
        </v-data-table>
        <jet-validation-errors class="mb-4" />
        <v-form @submit.prevent="submit" v-model="valid">
            <v-select
                outlined
                label="Лекция" :items="lectures" v-model="form.lecture_id"
                :rules="[v => (!!v ||  'Обязательное поле')]"
            />
            <v-textarea
                v-for="(params, field) in fields"
                :label="params.label"
                :hint="params.comment"
                persistent-hint
                v-model="form[field]"
                :key="field"
                :rules="[v => (!!v || params.notRequired || 'Обязательное поле')]"
                outlined
            >
                <template #message="{message}">
                    <div style="white-space: pre-wrap" v-html="message" />
                </template>
            </v-textarea>
            <v-btn type="submit" color="primary" :disabled="!valid">Отправить</v-btn>
        </v-form>
    </g-layout>
</template>

<script>
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";

export default {
    name: "CorrectAnswer",
    components: {JetValidationErrors},
    props: {
        lectures: {},
        questions: {},
    },
    data() {
        return {
            valid: false,
            form: this.formData(),
            fields: {
                off_topic: {label: 'Вопрос, по тому чего нет в лекции', comment: 'Придумайте вопрос по теме лекции, такой чтобы ответа на него не было в самой лекции.\n' +
                        '\n' +
                        'Примечание\n' +
                        'Не подходят вопросы по общепринятым понятиям, которые вы должны знать из других дисциплин, лекций'},
                hard: {label: 'Сложный вопрос', comment: 'Придумайте вопрос, ответить на который вам было бы сложно или ответ на него вы не понимаете.\n' +
                        '\n' +
                        'Здесь же можно задать вопрос, если вам действительно что-то было не понятно.\n' +
                        '\n' +
                        'Можно задать несколько вопросов.\n' +
                        '\n' +
                        'ВАЖНО\n' +
                        'Вопрос придумать обязательно, даже если вам все понятно!!!'},
                test: {label: 'Вопрос с вариантами ответа', comment: 'Придумайте вопрос по лекции с вариантами ответа (варианты ответа впишите ниже'},
                test_answer_right: {label: 'Вариант ответа 1, он же правильный ответ', comment: null},
                test_answer_2: {label: 'Вариант ответа 2', comment: null},
                test_answer_3: {label: 'Вариант ответа 3', comment: null},
                test_answer_4: {label: 'Вариант ответа 4', comment: null},
                miss: {label: 'Косяк в лекции (не обязательно, пожелания тоже велкам)', comment: 'Ребят, ну бувает...    \n' +
                        '`¯\\_(ツ)_/¯`\n' +
                        'Если нашли - скажить хоть)', notRequired: true},
            }
        }
    },
    computed: {
        headers(){
            let res =  []
            for(let [value, params] of Object.entries(this.fields)){
                res.push({value, text: params.label})
            }
            return res
        }
    },
    methods: {
        formData(){
            return this.$inertia.form({
                lecture_id: null,
                off_topic: '',
                hard: '',
                test: '',
                test_answer_right: '',
                test_answer_2: '',
                test_answer_3: '',
                test_answer_4: '',
                miss: '',
            })
        },
        submit() {
            this.form.post(this.route('correct-answer'), {
                onSuccess: () => this.form.reset(),
            })
        }
    }
}
</script>

<style scoped>

</style>
