<template>
    <g-layout>
        <v-autocomplete
            :items="lectures" :value="parseInt(lecture_id)" @input="loadLecture"
            label="Выберите лецию"
        />
        <div
            v-if="lecture_id"
            v-for="(items, type) in answers" :key="type">
            <h2>{{type === 'hard'? 'Сложный вопрос' : 'Вопрос по тому чего не было в лекции'}}</h2>
            <v-data-table
                title="Answers"
                sort-by="question"
                :headers="[
                {
                    text: 'Вопрос',
                    value: 'question'
                },
                {
                    text: 'Ответ',
                    value: 'answer'
                }
            ]" :items="items" />
        </div>
    </g-layout>
</template>

<script>
export default {
    name: "CorrectAnswerList",
    props: ['items', 'lectures', 'lecture_id'],
    computed: {
        answers(){
            let hard = [];
            let off_topic = [];
            for (let item of this.items){
                if(item.off_topic_answer){
                    off_topic.push({
                        question: item.off_topic,
                        answer: item.off_topic_answer
                    });
                }
                if(item.hard_answer){
                    hard.push({
                        question: item.hard,
                        answer: item.hard_answer
                    })
                }
            }
            return {hard, off_topic}
        }
    },
    methods: {
        loadLecture(lecture_id) {
            this.$inertia.visit(`/correct-answer/${lecture_id}`)
        }
    }
}
</script>

<style scoped>

</style>
