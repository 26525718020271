var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g-layout',[_c('v-autocomplete',{attrs:{"items":_vm.lectures,"value":parseInt(_vm.lecture_id),"label":"Выберите лецию"},on:{"input":_vm.loadLecture}}),_vm._v(" "),_vm._l((_vm.answers),function(items,type){return (_vm.lecture_id)?_c('div',{key:type},[_c('h2',[_vm._v(_vm._s(type === 'hard'? 'Сложный вопрос' : 'Вопрос по тому чего не было в лекции'))]),_vm._v(" "),_c('v-data-table',{attrs:{"title":"Answers","sort-by":"question","headers":[
            {
                text: 'Вопрос',
                value: 'question'
            },
            {
                text: 'Ответ',
                value: 'answer'
            }
        ],"items":items}})],1):_vm._e()})],2)}
var staticRenderFns = []

export { render, staticRenderFns }