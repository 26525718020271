import Vue from 'vue';
import Vuex from 'vuex';
import DictionaryModule from "./modules/DictionaryModule";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        dictionary: DictionaryModule,
    }
})
