<template>
    <g-layout>
        <v-card>
            <v-card-title>Тест завершен</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <p>Отвечено на {{answers}} из {{count}}, из них правильных {{valid}}</p>
                        <p>
                            Процент правильных ответов
                            <v-progress-circular
                                :rotate="90"
                                :size="100"
                                :width="15"
                                :value="percent"
                                color="red"
                            >
                                {{percent}} %
                            </v-progress-circular>
                        </p>

                    </v-col>
                    <v-col>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </g-layout>
</template>

<script>
export default {
    name: "TestResult",
    props: ['percent', 'count', 'answers', 'valid']
}
</script>

<style scoped>

</style>
