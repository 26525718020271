<template>
    <v-app>
        <v-main class="main">
            <div
                v-if="selectedColor"
                class="pa-5"
            >
                <v-btn @click="selectedColor = null" class="mb-5">Назад</v-btn>
                <div
                    class="d-flex flex-wrap"
                    style="gap: 10px;"
                >
                    <div class="group">
                        Цвет
                        <v-card
                            :color="selectedColor"
                            class="color"
                        >
                        </v-card>
                    </div>
                    <div
                        v-for="i in 3" :key="i"
                        class="group"
                    >
                        Комплиментарные {{i}}
                        <div class="d-flex flex-wrap">

                            <v-card
                                v-for="j in i" :key="j"
                                :color="selectedColor"
                                class="color"
                                :style="'filter: hue-rotate('+(360 / (i + 1) * j)+'deg)'"
                            ></v-card>
                        </div>
                    </div>

                    <div class="group">
                        Аналогичные
                        <div class="d-flex flex-wrap">
                            <v-card
                                :color="selectedColor"
                                class="color"
                                :style="'filter: hue-rotate(-30deg)'"
                            ></v-card>
                            <v-card
                                :color="selectedColor"
                                class="color"
                                :style="'filter: hue-rotate(30deg)'"
                            ></v-card>
                        </div>
                    </div>
                    <div class="group">
                        Контрастная триада
                        <div class="d-flex flex-wrap">
                            <v-card
                                :color="selectedColor"
                                class="color"
                                :style="'filter: hue-rotate(150deg)'"
                            ></v-card>
                            <v-card
                                :color="selectedColor"
                                class="color"
                                :style="'filter: hue-rotate(210deg)'"
                            ></v-card>
                        </div>
                    </div>
                    <div class="group">
                        Монохромные
                        <div class="monogrid" :style="{background: selectedColor}">
                            <template v-for="b in 10">
                                <div
                                    v-for="c in 10"
                                    :key="b + '_' + c"
                                    :style="'background: '+selectedColor+';filter: brightness('+b*20+'%) saturate('+c*20+'%)'"
                                ></div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-else
                class="colors_grid"
            >
                <v-card
                    v-for="baseColor in baseColors" :key="baseColor"
                    :color="baseColor"
                    class="color"
                    @click="selectedColor = baseColor"
                >
                    <div
                        v-for="i in 3" :key="i"
                    >
                        <div class="d-flex flex-wrap">
                            <div
                                v-for="j in i" :key="j"
                                class="color_mini"
                                :style="'filter: hue-rotate('+(360 / (i + 1) * j)+'deg); background: '+baseColor"
                            ></div>
                        </div>
                    </div>
                    <div >
                        <div class="d-flex flex-wrap">
                            <div
                                class="color_mini"
                                :style="'filter: hue-rotate(-30deg); background: '+baseColor"
                            ></div>
                            <div
                                class="color_mini"
                                :style="'filter: hue-rotate(30deg); background: '+baseColor"
                            ></div>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex flex-wrap">
                            <div
                                class="color_mini"
                                :style="'filter: hue-rotate(150deg); background: '+baseColor"
                            ></div>
                            <div
                                class="color_mini"
                                :style="'filter: hue-rotate(210deg); background: '+baseColor"
                            ></div>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-main>
    </v-app>
</template>

<script>

export default {
    name: "StyleHelper",
    methods: {
        rotate() {
            return rotate
        }
    },
    data: () => ({
        selectedColor: null,
        baseColors: [
            // '#00ffff',
            '#EAE7D4', '#eae5d1', '#ddd5ca', '#cbc4ba', '#b9b3a3', '#d0b598', '#b89784', '#958273', '#796861', '#61544b',
            '#cecacb', '#aca4a2', '#7c736e', '#8e8e86', '#575d59', '#9c7966', '#966b64', '#7d5850', '#68514b', '#644c4c',
            '#b4af99', '#8e8d7b', '#6d6656', '#8e9583', '#545857', '#3f3f41',
            // '#537BAC',
        ]
    })
}
</script>

<style scoped>
.main .col{
    flex-shrink: 0;
    min-width: 100px;
}
.colors_grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}
.colors_grid .color{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.colors_grid .d-flex{
    justify-content: space-around;
}
.color{
    min-width: 100px;
    aspect-ratio: 1;
}
.color_mini{
    width: 15%;
    aspect-ratio: 1;
}
.color_complimentary{
    filter: hue-rotate(180deg);
}
.monogrid{
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(10, 50px);
    grid-template-rows: repeat(10, 50px);
}
.group{
    padding: 10px;
    box-shadow: #2d3748 0 0 5px;
    border-radius: 10px;
}
.group .d-flex{
    gap: 10px;
}
</style>
