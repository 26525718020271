<template>
    <g-layout>
        <h1>Введение</h1>
        <v-stepper vertical non-linear>
            <v-stepper-step step="1" editable>HTML</v-stepper-step>
            <v-stepper-content step="1">
                <p>HTML - HyperText Markup Language, язык (стандарт) разметки документов (подробнее в википедии)</p>
                <p>Язык описывает содержимое веб-страницы, а именно ее элементы и порядок их отображения, вложенности друг в друга.</p>
                <p>Код на языке HTML состоит из комментариев, тегов и декларации doctype</p>
            </v-stepper-content>
            <v-stepper-step step="2" editable>Комментарий</v-stepper-step>
            <v-stepper-content step="2">
                <web-code :code="codes.comment" hide-preview/>
                <p>Позволяет писать поясняющий код в разметке, временно прятать из кода ненужные участки.</p>
            </v-stepper-content>
            <v-stepper-step step="3" editable>Тег</v-stepper-step>
            <v-stepper-content step="3">
                <p>Это основная структура HTML представляющая собой обозначение начала и окончание элемента веб-страницы.</p>
                <p>По способу написания выделяют <strong>парные и одиночные</strong>.</p>
                <p>У <strong>парных</strong> тегов есть открывающая часть, обозначающая начало элемента и закрывающая часть
                    означающая окончание элемента, между ними - содержимое тега,
                    в содержимом может быть текст или другие теги. Любой парный тег может содержать в себе другие теги
                </p>
                <p><strong>Одиночный</strong> тег - тег который не может содержать другие теги, текстовая информация указывается в виде аттрибута</p>
                <web-code :code="codes.tag" hide-preview/>
            </v-stepper-content>
            <v-stepper-step step="4" editable>Примеры тегов</v-stepper-step>
            <v-stepper-content step="4">
                <web-code :code="codes.tag2" />
            </v-stepper-content>
            <v-stepper-step step="5" editable>Общая структура страницы</v-stepper-step>
            <v-stepper-content step="5">
                <web-code :code="codes.structure" />
            </v-stepper-content>
            <v-stepper-step step="6" editable>Виды тегов по особенностям отображения</v-stepper-step>
            <v-stepper-content step="6">
                <p>Выделяют три вида тегов по особенностям отображения: строчный, блочный и служебный</p>
                <p><strong>Строчными</strong> называются такие элементы веб-страницы,
                    которые являются частью текста. В основном они используются для изменения вида текста или его логического выделения.</p>
                <p>Большинство строчных тегов простые парные</p>
                <p><strong>Блочные</strong> теги характеризуются тем, что занимают всю доступную ширину, высота элемента определяется его содержимым, и он всегда начинается с новой строки.</p>
                <p><strong>Служебные</strong> теги - теги, необходимые для структурирования HTML-документа и управления некоторыми параметрами вкладки браузера</p>
            </v-stepper-content>
        </v-stepper>
    </g-layout>
</template>

<script>
export default {
    name: "HTMLIntro",
    props: ['codes']
}
</script>

<style scoped>

</style>
