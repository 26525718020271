<template>
    <v-form
        v-if="!completed"
        @submit.prevent="save"
    >
    <v-card
        class="mb-5"
    >
        <v-card-title>
            {{item.user_id}}
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <v-textarea v-model="values.hard" label="Сложный"  tabindex="-1" />
                </v-col>
                <v-col cols="6">
                    <v-textarea v-model="values.hard_answer" label="ответ"/>
                </v-col>
                <v-col cols="6">
                    <v-textarea v-model="values.off_topic" label="Не из темы"  tabindex="-1" />
                </v-col>
                <v-col cols="6">
                    <v-textarea v-model="values.off_topic_answer" label="ответ" />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" type="submit">Сохранить</v-btn>
            <v-alert type="success" v-if="success">Сохранено</v-alert>
        </v-card-actions>
    </v-card>
    </v-form>
</template>

<script>
import axios from 'axios'
export default {
    name: "AdminCorrectAnswerItem",
    props: {
        item: Object
    },
    data: () => ({
        values: {},
        success: false,
    }),
    computed: {
        completed(){
            return this.success && this.values.hard_answer && this.values.off_topic_answer
        }
    },
    mounted() {
        this.values = this.item
    },
    watch: {
        values: {
            deep: true,
            handler() {
                this.success = false;
            }
        }
    },
    methods: {
        async save(){
            this.success = false;
            let res = await axios.post("/admin/correct-answer", this.values)
            this.success = res.data.success;
        }
    }
}
</script>

<style scoped>

</style>
