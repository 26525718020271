import GlobalLayout from "./components/Layout/GlobalLayout";
import {createInertiaApp, Link} from '@inertiajs/inertia-vue'
import Vue from 'vue'
import Vuex from 'vuex'
import vuetify from './plugins/vuetify'
import RouterLink from "./components/Global/RouterLink";
import VFormBase from 'vuetify-form-base';
import store from "./store";
import DictionaryLoader from "./components/Global/DictionaryLoader";
import VTiptap from "./components/Global/VTiptap";
import {PrismEditor} from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/themes/prism.css'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import CKEditor from "@ckeditor/ckeditor5-vue2"
import WebCode from "@/components/WebCode";
// import ckeditor from "ckeditor5"
import VueShowdown from 'vue-showdown'
import Vuebar from 'vuebar';
Vue.use(Vuebar);

// Глабальные компоненты
Vue.component('web-code', WebCode)
Vue.component('g-layout', GlobalLayout)
Vue.component('InertiaLink', Link)
Vue.component('RouterLink', RouterLink)
Vue.component('v-form-base', VFormBase)
Vue.component('dictionary-loader', DictionaryLoader)
// Vue.component('fortus-calendar', FortusCalendar)
// Vue.component('v-fortus-calendar', FortusCalendarSchemaWrapper)
Vue.component('v-tiptap', VTiptap)

Vue.component("prism-editor", PrismEditor);
// Vue.component("ckeditor", ckeditor);
Vue.component('v-style', {
    render: function(createElement) {
        return createElement('style', this.$slots.default)
    }
})
Vue.component('v-script', {
    render: function(createElement) {
        return createElement('script', this.$slots.default)
    }
})
Vue.use( CKEditor );
Vue.use(VueShowdown, {
    // set default flavor of showdown
    flavor: 'allOn',
    // set default options of showdown (will override the flavor options)
    options: {
        emoji: false,
    },
})

Vue.prototype.$highlighter = (lang) => {
    const f = function (code) { return highlight(code, languages[lang]) } // languages.<insert language> to return html with markup
    // console.log(f)
    return f
}

// Миксин для определения путей
Vue.mixin({ methods: { route } });

Vue.use(Vuex)
createInertiaApp({
    resolve: name => require(`./pages/${name}`),
    setup({ App, props, plugin }) {
        Vue.use(plugin)

        new Vue({
            store,
            vuetify,
            render: h => h(App, props),
        }).$mount(app)
    },
})
