import axios from "axios";
export default {
    namespaced: true,
    state: {
        dictionary: {},
        dictionaryLoading: false,
    },
    mutations: {
        addDictionary(state, val){
            for(let [key, value] of Object.entries(val)){
                state.dictionary[key] = value
            }
        },
        loading(state, val){
            state.dictionaryLoading = val
        }
    },
    actions: {
        async loadDictionary({commit, state}, value){
            let load = value.filter((v) => !(state.dictionary[v] ?? false))
            if(load?.length > 0) {
                commit('loading', true)
                // let res = await RPCRequest.request('/dictionary', 'post', {load})
                let res = await axios.post('/dictionary',  {load})
                commit('addDictionary', res.data)
                commit('loading', false)
            }
            let returns = {}
            for(let key of value){
                returns[key] = state.dictionary[key] ?? []
            }
            return returns
        }
    }
}
