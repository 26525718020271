<template>
    <g-layout title="CSS-селекторы">
      <v-row style="position: relative;">
        <v-col style="max-width: 200px;overflow-y: auto;max-height: calc(100vh - 65px);position: sticky;top:100px;" @dblclick="saveFile">
          <v-list class="css-properties-list">
            <v-list-item link v-for="(item, index) in this.csv" :key="item"
                         @click="selected = index"  class="css-properties-list__item">
              <v-list-item-content>
                <v-list-item-title>{{item.title}}</v-list-item-title>
                <!--              <v-list-item-subtitle>{{item.prop}}</v-list-item-subtitle>-->
              </v-list-item-content>
            </v-list-item>
          </v-list>
    <!--      <v-alert-->
    <!--          color="primary" dark-->
    <!--          v-for="item in csv"-->
    <!--          :outlined="item.prop !== prop"-->
    <!--          @click="prop = item.prop"-->
    <!--          style="cursor: pointer"-->
    <!--      >-->
    <!--        <div style="font-size: 50%;">{{item.type}}</div>-->
    <!--        <div style="font-size: 75%;">{{item.title}}</div>-->
    <!--        {{item.prop}}-->
    <!--      </v-alert>-->
        </v-col>
        <v-col style="overflow-y: auto;max-height: calc(100vh - 65px);" v-if="propData">
          <v-text-field v-model="propData.title"/>
          <p v-if="propData.desc" v-html="propData.desc" style="font-size: 90%;"/>
          <web-code v-if="propData" :code.sync="propData" :key="selected"/>
        </v-col>
      </v-row>
    </g-layout>
</template>

<script>
import WebCode from "@/components/WebCode";
// import testHtml from '@/codes/test/test.html?raw';
// import testCSS from '@/codes/test/test.css?raw';
// // const testCSS = require('@/codes/test/test.css?raw')
// console.log({testHtml, testCSS})
export default {
  name: "CSSSelectors",
  components: {WebCode},
  data: () => ({
    csv: require('@/json/selectors.json'),
    selected: 0,
  }),
  computed: {
    tree(){
      let res = {}
      for(let item of this.csv){
        res[item.type ?? ''] ??= []
        res[item.type ?? ''].push(item)
      }
      return res
    },
    props(){
      return this.csv.map(v => ({text: v.title ?? v.prop, value: v.prop}))
    },
    propData: {
      get() {
        return this.csv[this.selected]
      },
      set(v){
        this.$set(this.csv, this.selected, {...this.propData, ...v})
      }
    }
  },
  mounted() {
    this.prop = this.props[this.props.length - 1].value
  },
  methods: {
    saveFile() {
      const data = JSON.stringify(this.csv)
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
          a = document.createElement('a');
      a.download = "test.json";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    }
  }
}
</script>

<style scoped>
html, body{
  overflow: hidden;
}
.css-properties-list .v-list-item__title{
  font-size: 12px;
  white-space: break-spaces;
}
.css-properties-list__item{
  /*padding-left: 30px;*/
  border: 1px solid #1976d2;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

</style>
